<template>
  <section class="sustainedDevelopment">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <el-tabs :tab-position="tabPosition" class="tab" v-model="editableTabsValue" @tab-click="handleClick">
        <el-tab-pane class="sustainedDevelopment_my" name="sustainedDevelopment_my" label="可持续发展" :disabled="isDisabled"></el-tab-pane>
        <el-tab-pane name="1" label="通向可持续发展之路" :lazy="lazy">
          <section class="article">
            <h4 class="title">通向可持续发展之路</h4>
            <section v-for="(item, index) in data1" :key="index" class="sustainedDevelopment_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane name="2" label="注重可持续发展" :lazy="lazy">
          <section class="article">
            <h4 class="title">注重可持续发展</h4>
            <section v-for="(item, index) in data2" :key="index" class="sustainedDevelopment_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane name="3" label="构建可持续的组织" :lazy="lazy">
          <section class="article">
            <h4 class="title">构建可持续的组织</h4>
            <section v-for="(item, index) in data3" :key="index" class="sustainedDevelopment_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane name="4" label="生态与繁荣并行" :lazy="lazy">
          <section class="article">
            <h4 class="title">生态与繁荣并行</h4>
            <section v-for="(item, index) in data4" :key="index" class="sustainedDevelopment_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
                <el-table v-if="val.table" :data="val.table" border style="width: 100%">
                  <el-table-column prop="stage" label="信息化发展阶段" width="120px" />
                  <el-table-column prop="trait" label="主要特点" />
                  <el-table-column prop="cycle" label="时间周期" width="120px" />
                  <el-table-column prop="case" label="典型案例" />
                </el-table>
              </section>
            </section>
          </section>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
import imageBanner from '../components/ImageBanner.vue'
export default {
  name: 'sustainedDevelopment',
  components: {imageBanner},
  data () {
    return {
      tabPosition: 'left',
      isDisabled: true,
      editableTabsValue: '1',
      lazy: true,
      imgBannerObj: {
        url: require('../assets/images/aboutus/06-可持续发展.png')
      },
      data1: [
        {title: '', content: [
          {con: '我们认为企业的生存、成长和发展都应该遵循客观规律，我们更愿意将企业视为一个活着的生命体，他应该充满热情和热爱，具有勇气意志和信心，秉承良好的品德和信誉，为社会创造价值。'},
          {con: '我们将通过制定正确的经营战略、不断增强企业的核心竞争力、以及培育优秀的企业文化来实现企业的持续发展。'},
        ]},
        {title: '（一）制定正确的经营战略', content: [
          {con: '企业的经营战略就是企业的发展方向，方向的正确与否，决定着企业的生死存亡。战略正确就能做到步步领先，占领优势，并使其发挥到极致。相反，战略错误将处处被动，甚至会付出被淘汰出局的惨重代价。'},
          {con: '我们经营战略核心在于用技术优势和个性化服务能力的构建属于我们的市场壁垒，并努力开创新的市场；同时我们的经营一定要保持审慎的毛利，切不可以因占有优势地位而扩大毛利，以此来保持警惕和对市场的敬畏。'},
        ]},
        {title: '（二）增强企业的核心竞争力', content: [
          {con: '战略确定固然重要，但更重要的是如何组织企业资源，分阶段、分步骤的进行战略实施。如果没有一个好的战略，再好的战术也会变得没有意义，而有了好的战略，在实施中战术跟不上，也会毁掉一个卓越的战略构想。战略是企业决策层的职能，而战略的组织实施就属于企业管理层的事了，管理人员如何采取各种战术措施确保战略的实现，其中心工作就是要不断增强企业的核心竞争力。'},
          {con: '企业核心竞争力是企业经营理念、经营机制、企业资源和企业文化等因素的有机结合体，是企业综合实力的表现，是偷不去、买不来、带不走的企业所特有的能力。我们会在以下几点维护好我们的核心竞争力：'},
          {con: '1.实施人才开发战略，建设一支知识结构合理、符合市场和企业发展要求的技术、管理人才队伍。'},
          {con: '关于我们的人才梯队的建设：对外我们将通过各种渠道招贤纳士，广泛吸引人才。对内，我们将采取不同形式，结合公司发展需要，大力培养人才。我们务必要做到知人善任，用其所长，避其所短，做到“贤者居上，智者居侧，能者居前”。'},
          {con: '2.实施技术开发战略，提高技术、装备水平，增强市场竞争能力，'},
          {con: '在当今科学技术发展突飞猛进、日新月异的时代，一个企业的技术储备和人才储备决定了这个企业能否可持续发展的程度。对此，要增强科技创新意识，提高对科技的重视程度，加大对科技的投入，建立有效的技术开发运行机制，采用CMMi 3级以上的标准严格的进行项目开发管理，确保技术研发的效率和质量。'},
          {con: '3.实施合理成本战略，走精细管理之路，提高企业的整体管理水平'},
          {con: '企业的最终目的是争取效益的最大化，企业生存与发展的根本取决于它的赢利能力。降本增效是企业永恒的主题，在实际操作运行过程中，尽管方法不同，但归纳起来，不外乎两方面的内容，一是依靠科技降低成本；二是依靠管理降低成本。'},
          {con: '在科技降本方面，一是通过科技进步，应用先进的生产技术，改进生产工艺，提高劳动生产率，达到直接降低生产成本的目的：二是通过科研攻关，克服生产工艺过程中存在的各种复杂问题，提高产品质量，避免操作事故，从而减少损失，实现间接降低成本的目的。'},
          {con: '在管理降本方面，一是加强成本费用定额管理，结合实际生产，实施“成本倒逼”，制定科学、先进、合理、可行的内部费用消耗定额，建立有效的成本控制机制、予警机制、考核激励机制，完善成本管理制度，加大奖惩幅度，确保成本的有效控制；二是加强资金管理，进一步完善资金管理制度，调整和细化资金集中管理环节，提高资金使用的整体效果。加强资金的内部控制，建立以财务为中心的成本决策、控制体系和成本分析制度，制定严格的经济活动管理规定和项目投资审批程序，做到权限清楚，责任分明；三是大力推行管理创新工作，增强管理创新意识，树立只有不断创新，我们才能持续发展的观念。我们已进入无边界的竞争时代，那种凝固不变的常规管理模式已远远不能适应，必将被新型的管理方式所取代。'},
          {con: '与高校或研究机构的资深研究人员或学院精密联系，获取更为前沿的信息和技术，积极参与高校的产学研方面的合作。高校的研发机构数量庞大，种类繁多，有其一定的优势，我们可以取其精华，为我所用，同时又把市场的需求告知高校，助力其研发的成果更好的落地，为科研成果转化提高效率。'},
        ]},
        {title: '（三）培育优秀的企业文化', content: [
          {con: '企业文化是根植于企业本身土壤的独特文化和制度，是企业核心竞争力的重要因素。'},
          {con: '优秀的企业文化一旦形成，就能将企业的追求和社会公德、是非标准等融化在全体职工的思想和行动之中，使企业目标与个人目标达到高度契合，人人都自觉地把追求企业目标当作追求个人目标，从而，职工产生极强的向心力，企业形成极强的凝聚力。因此，要以战略的眼光，重视企业文化，大力加强新形式下企业文化建设。'},
          {con: '企业文化建设是一项复杂的“人本”工程，要从长计仪，有计划、按步骤地从基础工作抓起，树立正确的经营理念，培养职工树立正确的价值观，把企业精神、经营观念和被全体职工认同的价值观、行为准则有机地结合在一起，形成克服困难，促进发展的强大合力，充分发挥企业文化在市场竞争中内强素质、外树形象的重要作用。'},
        ]},
      ],
      data2: [
        {title: null, content: [
          {con: '旋荣的发展凝聚了我们全员的心血，我们希望他能够长久且健康的存续，因此我们的十分注重企业的可持续发展。'},
          {con: '旋荣的可持续发展不仅需要我们细心的打理和经营，更重要的是需要秉承一些基本原则：'},
          {con: '（一）坚持诚信、平等、奋斗、务实、创新的企业文化；'},
          {con: '（二）坚决不做有损公司的声誉和信誉的事情，不为短期利益所动；'},
          {con: '（三）坚持以人为本的重要原则，聆听员工诉求，解决员工问题，让员工能够充分发挥主观能动性；'},
          {con: '（四）坚决反对内耗，搞小团体，对人不对事。我们提倡互帮互助，对事不对人，公司内部有事直接说，不用绕弯子，凡是讲个理，并不以职位高低为决策的唯一标准。'},
        ],}
      ],
      data3: [
        {title: null, content: [
          {con: '公司的传承是构建可持续发展组织的目的。我们将有两大原则：'},
          {con: '（一）开展员工持股计划，只要满足一定条件的员工均能参与；'},
          {con: '（二）公司对新进的年轻员工，尤其是应届生均进行师徒制的培养；'},
          {con: '（三）公司开展梯队建设的培养计划，在每个年龄段都优选若干名的员工进行长期全方位培养，赋予更多单独团队的决策和实操工作，加快成长；'},
          {con: '（四）公司将不断完善职业经理人制度，未来的传承将在公司现有员工中进行选拔，而且优选员工的子女，公司的传承将对下一代的子女一视同仁，一切为了公司好，让贤者上。'},
        ]},
      ],
      data4: [
        {title: null, content: [
          {con: '旋荣的发展应秉承不损坏自然环境的原则，如果有条件的话，将力所能及的参与自然环境的优化，并视为企业未来的责任之一。'},
          {con: '同时，旋荣力争构建一个TO B领域的良好商业生态，定位于MRO类产品的中宗（区别于大宗和小宗）产品交易，依托公司的助力飞现代智慧供应链平台，为合作伙伴做全面赋能，实现商业领域的生态与繁荣。'},
        ]},
      ],
    }
  },
  watch: {
    $route () {
      this.getEditableTabsValue()
    }
  },
  created () {
    this.getEditableTabsValue()
  },
  methods: {
    getEditableTabsValue () {
      if ( this.$route.query.id ) this.editableTabsValue = this.$route.query.id
    },
    handleClick (org) {
      if(org.index == 0 && /new_my/.test(org.$el.className)) {
        this.$router.push({path: '/sustainedDevelopment', query: {id: '1'}})
      }
    }
  }
}
</script>

<style scoped>

/* .sustainedDevelopment{max-width:1920px;margin:0 auto;background-color:#ecf0f5;padding-bottom:1rem;} */
.sustainedDevelopment img{width:100%;}
/* .sustainedDevelopment .tab{font-size:1rem;margin:2rem 15%;}
.sustainedDevelopment /deep/ .el-tabs__nav{background-color:#fff;}
.sustainedDevelopment /deep/ .el-tabs--left .el-tabs__item{padding:.5rem 1rem;text-align:center !important;font-size:1rem;height:auto;line-height:auto;}
.sustainedDevelopment /deep/ .el-tabs__item.is-active{background-color:#a2bee7;color:#fff;font-weight:600;}
.sustainedDevelopment /deep/ .el-tabs__item.is-disabled{font-size:1.6rem !important;padding:1.4rem 2rem !important;background-color:#7895be !important;color:#fff !important;}
.sustainedDevelopment /deep/ .el-tabs--left .el-tabs__active-bar{height:0 !important;} */
.sustainedDevelopment .article {font-size:1rem;text-align: left;padding:1rem 2rem;background-color:#fff;min-height: 20rem;}
.sustainedDevelopment .article .title{font-size: 2rem; text-align:center;}
.sustainedDevelopment .article section.sustainedDevelopment_content {border-bottom:1px solid #ccc;padding: .8rem 0;}
.sustainedDevelopment .article section.sustainedDevelopment_content:last-child {border-bottom:none;}
.sustainedDevelopment .article h5{font-weight: 600;font-size: 1.2rem;padding: .2rem;}
.sustainedDevelopment .article h6{font-weight: 600;font-size: 1rem;padding: .2rem;}
.sustainedDevelopment .article p{padding: .4rem; line-height: 1.6; text-indent: 2em;}
.sustainedDevelopment /deep/ .el-table .cell {
  white-space: pre-wrap;
}
</style>